import React from 'react'
import styled from 'styled-components'
import { Typography } from '@rmwc/typography'
import '@rmwc/typography/styles'

export type ContainerProps = {
  show?: boolean
}

const Container = styled.div<ContainerProps>`
  & {
    display: ${(props) => (props.show ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    h3 {
      font-weight: 600;
      margin-bottom: 0;
    }

    p {
      max-width: 25rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const FlexWrapper = styled.div``

export type CustomPlanProps = {
  show: boolean
}

const CustomPlan: React.FC<CustomPlanProps> = ({ show = false }) => {
  return (
    <Container show={show}>
      <FlexWrapper>
        <Typography use="headline6" tag="h3" theme="primary">
          Looking for something bigger?
        </Typography>
        <Typography use="body1" tag="p">
          Not a problem, use as much as you need!
        </Typography>
        <Typography use="body2" tag="p">
          Our metered, usage-based pricing means there are no limits to how much, or how little, you
          can test.
        </Typography>
      </FlexWrapper>
    </Container>
  )
}

export default CustomPlan
