import { format } from 'd3-format'

export const freeTierVUH = 500
export const middleTierVUH = 20e3
export const middleTierPrice = 0.045
export const upperTierPrice = 0.036

export const sliderSettings = {
  min: freeTierVUH,
  max: 60e3,
  step: 500,
  initial: 6500,
}

export function getCost(vuh: number = 0): number {
  if (vuh <= freeTierVUH) {
    return 0
  } else if (vuh <= middleTierVUH) {
    return (vuh - freeTierVUH) * middleTierPrice
  } else {
    return (middleTierVUH - freeTierVUH) * middleTierPrice + (vuh - middleTierVUH) * upperTierPrice
  }
}

export const formatDecimal = format(',d')
