import React from 'react'
import styled from 'styled-components'
import { Typography } from '@rmwc/typography'

export type HeaderProps = {}

const Container = styled.div`
  & {
    margin: auto;
    padding: 2rem 2rem 0;
    max-width: 39rem;

    .mdc-typography--body2 {
      margin-left: auto;
      margin-right: auto;
    }

    .mdc-typography--headline5,
    .mdc-typography--headline6 {
      margin-bottom: 0;
    }
  }
`

const Header: React.FC<HeaderProps> = () => {
  return (
    <Container>
      <Typography use="headline5" tag="h2">
        Start for free and scale to your needs
      </Typography>

      <Typography use="body1" tag="p">
        Flexible usage-based pricing means you only pay for the tests you run.
      </Typography>

      <Typography use="body2" tag="p">
        Use Flood when you want, as much as you want, and then get a simple bill for your use at the
        end of the month. Tiered pricing also means the more VUH you use, the cheaper they become!
        Whether you’re ramping up for a big event or performing routine checks, with metered usage
        you’re in control of how much you spend.
      </Typography>

      <Typography use="headline6" tag="h3">
        Need help budgeting?
      </Typography>

      <Typography use="body2" tag="p">
        Select your anticipated VUH usage for the month below to see what it will cost. Need a
        little more? No worries! Having a quiet month? It’s all good! You’ll only ever pay for what
        you use.
      </Typography>
    </Container>
  )
}

export default Header
