import React from 'react'
import { ThemeProvider } from '@rmwc/theme'
import { Card } from '@rmwc/card'
import Header from './components/Header'
import Calculator from './components/Calculator'
import Footer from './components/Footer'

import '@rmwc/theme/styles'
import '@rmwc/typography/styles'
import '@rmwc/card/styles'
import './App.css'

const theme = {
  primary: '#4285f4',
  onPrimary: '#fff',
  secondary: '#36b37e',
  onSecondary: '#fff',
}

function App() {
  return (
    <ThemeProvider options={theme}>
      <Card className="app mdc-typography">
        <Header />
        <Calculator />
        <Footer />
      </Card>
    </ThemeProvider>
  )
}

export default App
