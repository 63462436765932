import React from 'react'
import { CardActions, CardActionButton } from '@rmwc/card'
import '@rmwc/card/styles'

export type FooterProps = {}

const Footer: React.FC<FooterProps> = () => {
  const url = 'https://app.flood.io/sign-up'

  return (
    <CardActions style={{ justifyContent: 'center', padding: '2rem' }}>
      <CardActionButton raised theme="secondaryBg" onClick={() => (window.location.href = url)}>
        Sign Up Now
      </CardActionButton>
    </CardActions>
  )
}

export default Footer
