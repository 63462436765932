import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Slider } from '@rmwc/slider'
import { Typography } from '@rmwc/typography'
import CustomPlan from './CustomPlan'
import Currency from './Currency'
import { freeTierVUH, sliderSettings, getCost, formatDecimal } from '../utils'
import '@rmwc/slider/styles'
import '@rmwc/typography/styles'

const { min, max, step, initial } = sliderSettings

const Container = styled.div`
  & {
    padding: 0 2rem;
    user-select: none;

    @media (min-width: 680px) {
      padding: 0 4rem;
    }
  }
`

const Values = styled.div`
  position: relative;
`

export type RowProps = {
  hidden?: boolean
}

const Row = styled.div<RowProps>`
  & {
    display: block;
    text-align: center;
    padding: 2rem 0;
    transition: all 300ms ease-out;
    ${(props) =>
      props.hidden
        ? `
    opacity: 0.15;
    filter: blur(4px);
    transform: scale(0.9);
    `
        : ''}

    @media (min-width: 680px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3rem;
    }
  }
`

const Cell = styled.div`
  position: relative;

  &:first-of-type {
    margin-bottom: 1rem;
  }
  > * {
    margin: 0;
  }
  @media (min-width: 680px) {
    text-align: left;

    &:first-of-type {
      text-align: right;
      margin: 0;
    }
  }

  .mdc-typography--headline3 {
    font-weight: 700;
    font-size: 48px;
  }

  .free-message {
    position: absolute;
    bottom: -0.9rem;
    width: 100%;
  }
`

export type CalculatorProps = {}

const Calculator: React.FC<CalculatorProps> = () => {
  const [vuh, setVUH] = useState(initial)
  const [cost, setCost] = useState(getCost(initial))
  const [showCustomPlan, setShowCustomPlan] = useState(false)

  const FreeMessage = () => {
    if (cost === 0) {
      return (
        <Typography use="caption" tag="p" theme="primary" className="free-message">
          the first {freeTierVUH} VUH every month are free!
        </Typography>
      )
    }
    return <></>
  }

  useEffect(() => {
    setCost(getCost(vuh))
    setShowCustomPlan(vuh >= 60e3)
  }, [vuh])

  return (
    <Container>
      <Values>
        <Row hidden={showCustomPlan}>
          <Cell>
            <Typography use="overline" tag="p">
              Virtual User Hours
            </Typography>
            <Typography use="headline3" tag="p" theme="primary">
              {formatDecimal(vuh)}
            </Typography>
          </Cell>
          <Cell>
            <Typography use="overline" tag="p">
              Estimated Cost
            </Typography>
            <Typography use="headline3" tag="p" theme="primary">
              <Currency value={cost} />
            </Typography>
            <FreeMessage />
          </Cell>
        </Row>
        <CustomPlan show={showCustomPlan} />
      </Values>

      <Slider
        min={min}
        max={max}
        step={step}
        value={vuh}
        onInput={(evt) => setVUH(evt.detail.value)}
      />
    </Container>
  )
}

export default Calculator
