import React from 'react'
import styled from 'styled-components'
import { formatDecimal } from '../utils'

export type CurrencyProps = {
  symbol?: string
  value: number
}

const Container = styled.span`
  line-height: initial;
`

const Symbol = styled.span`
  vertical-align: super;
  padding-right: 0.25rem;
  font-size: 45%;
`

const Value = styled.span``

const Currency: React.FC<CurrencyProps> = ({ symbol = 'US $', value = 0 }) => {
  return (
    <Container>
      {value > 0 && <Symbol>{symbol}</Symbol>}
      <Value>{value > 0 ? formatDecimal(value) : 'Free'}</Value>
    </Container>
  )
}

export default Currency
